import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Send,Hourglass, MessageSquare, Plus, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const api = axios.create({
  baseURL: 'https://a805-2405-201-2032-9025-1d14-aaa-278a-4dc0.ngrok-free.app',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 30000
});

const QuickActionButton = ({ onClick, children }) => (
  <motion.button
    onClick={onClick}
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="group w-48 flex items-center justify-center gap-3 px-4 py-2.5 bg-gradient-to-r from-[#17464e] to-[#103a48] text-white text-sm
    rounded-full transition-all duration-200 border border-cyan-100 hover:border-cyan-200 mt-3"
  >
    <span className="text-sm font-medium text-white">{children}</span>
  </motion.button>
);





const TypingIndicator = () => (
  <motion.div 
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    className="flex items-center space-x-2 p-4 bg-cyan-50 rounded-lg max-w-[200px]"
  >
    <div className="flex space-x-1">
      {[0, 1, 2].map((i) => (
        <motion.div
          key={i}
          animate={{
            y: [0, -6, 0],
            opacity: [0.4, 1, 0.4]
          }}
          transition={{
            duration: 0.6,
            repeat: Infinity,
            delay: i * 0.2,
            ease: "easeInOut"
          }}
          className="w-2.5 h-2.5 bg-cyan-500 rounded-full"
        />
      ))}
    </div>
    <span className="text-sm text-cyan-600">AI is typing...</span>
  </motion.div>
);

  

const ChatInterface = () => {
  const [messages, setMessages] = useState({});
  const [chats, setChats] = useState([{ id: uuidv4(), name: 'New Chat' }]);
  const [activeChat, setActiveChat] = useState(chats[0].id);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const [waitingForEmail, setWaitingForEmail] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages[activeChat]]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  }, [inputMessage]);

  const createNewChat = () => {
    const newChatId = uuidv4();
    setChats(prev => [{ id: newChatId, name: 'New Chat' }, ...prev]); // Reverse order
    setActiveChat(newChatId);
    setMessages(prev => ({ ...prev, [newChatId]: [] }));
  };

  const deleteChat = (chatId, e) => {
    e.stopPropagation();
    setChats(prev => prev.filter(chat => chat.id !== chatId));
    setMessages(prev => {
      const newMessages = { ...prev };
      delete newMessages[chatId];
      return newMessages;
    });
    
    if (activeChat === chatId) {
      const remainingChats = chats.filter(chat => chat.id !== chatId);
      if (remainingChats.length > 0) {
        setActiveChat(remainingChats[0].id);
      } else {
        createNewChat();
      }
    }
  };

  const updateChatName = (chatId, firstMessage) => {
    setChats(prev => prev.map(chat => 
      chat.id === chatId 
        ? { ...chat, name: firstMessage.slice(0, 30) + (firstMessage.length > 30 ? '...' : '') }
        : chat
    ));
  };

  const formatTimestamp = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const startTypingIndicator = () => {
    setIsTyping(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 2000);
  };

  const getStaticResponse = (message) => {
    if (message === "How are you better than ChatGPT?") {
      return {
        content: `Our data is stored on Indian server using 256 bit encryption and i am a better expert on indian financial instruments and data.`,
        metadata: {},
        source: "static"
      };
    }
    return null;
  };

  const sendMessage = async (message) => {
    const staticResponse = getStaticResponse(message);
    if (staticResponse) {
      return staticResponse;
    }
    try {
      startTypingIndicator();
      const response = await api.post('/query', {
        user_id: 'darshit',
        query: message,
        conversation_id: activeChat,
        metadata: {
          additional_info: 'string'
        }
      });

      return {
        content: response.data.response || response.data.metadata?.explanation || 'No response available',
        metadata: response.data.metadata,
        source: response.data.source
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'ECONNABORTED') {
          throw new Error('Request timed out. Please try again.');
        }
        if (error.response) {
          throw new Error(`Server error: ${error.response.status} - ${error.response.data?.message || error.response.statusText}`);
        }
        if (error.request) {
          throw new Error('Unable to reach the server. Please check your internet connection.');
        }
      }
      throw new Error('An unexpected error occurred. Please try again later.');
    }
  };

  const handleEmailSubmission = async (email) => {
    try {
      const response = await axios({
        method: 'post',
        url: 'https://script.google.com/macros/s/AKfycbwaSKk1l4aKiCb1IB5QA11Nliz5acz-mX8OoHTBcVwr0o2XQONmXdm7PqYlNzGsXNZW/exec',
        headers: {
          'Content-Type': 'text/plain',
        },
        data: {
          email: email,
        },
      });
  
      // Check if the response has a success status
      if (response.data && response.data.status === "success") {
        setMessages((prev) => ({
          ...prev,
          [activeChat]: [
            ...(prev[activeChat] || []),
            {
              content:
                "Thank you for joining our waitlist! We'll keep you updated on our latest developments.",
              isUser: false,
              timestamp: formatTimestamp(),
            },
          ],
        }));
        setWaitingForEmail(false);
      } else {
        throw new Error(response.data?.message || "Failed to join the waitlist");
      }
    } catch (error) {
      console.error("Email submission error:", error);
      setMessages((prev) => ({
        ...prev,
        [activeChat]: [
          ...(prev[activeChat] || []),
          {
            content: "Sorry, there was an error processing your request. Please try again later.",
            isUser: false,
            isError: true,
            timestamp: formatTimestamp(),
          },
        ],
      }));
      setWaitingForEmail(false);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const userMessage = inputMessage.trim();
    setInputMessage('');
    setError(null);

    const timestamp = formatTimestamp();
    const currentMessages = messages[activeChat] || [];
    
    if (currentMessages.length === 0) {
      updateChatName(activeChat, userMessage);
    }

    setMessages(prev => ({
      ...prev,
      [activeChat]: [...currentMessages, { 
        content: userMessage, 
        isUser: true, 
        timestamp 
      }]
    }));

    if (waitingForEmail) {
      await handleEmailSubmission(userMessage);
      return;
    }

    setIsLoading(true);

    try {
      const response = await sendMessage(userMessage);
      setIsTyping(false);
      
      setMessages(prev => ({
        ...prev,
        [activeChat]: [...(prev[activeChat] || []), { 
          content: response.content,
          isUser: false,
          metadata: response.metadata,
          source: response.source,
          timestamp: formatTimestamp()
        }]
      }));
    } catch (error) {
      setError(error.message);
      setMessages(prev => ({
        ...prev,
        [activeChat]: [...(prev[activeChat] || []), { 
          content: error.message,
          isUser: false,
          isError: true,
          timestamp: formatTimestamp()
        }]
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleWaitlistClick = () => {
    const userMessage = "Join the Waitlist";
    setMessages(prev => ({
      ...prev,
      [activeChat]: [...(prev[activeChat] || []), {
        content: userMessage,
        isUser: true,
        timestamp: formatTimestamp()
      }, {
        content: "Please provide your email id in the chat.",
        isUser: false,
        timestamp: formatTimestamp()
      }]
    }));
    setWaitingForEmail(true);
  };



  return (
    <div className="flex h-screen bg-gradient-to-br from-gray-50 to-gray-100">
    {/* Sidebar */}
    <div 
      className={`fixed h-full bg-gradient-to-b from-cyan-900 to-gray-900 w-64 z-30
      transition-all duration-300 ease-in-out transform
      ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
    >
      <div className="p-4">
        <button
          onClick={createNewChat}
          className="w-full flex items-center gap-2 px-4 py-3 text-white bg-gradient-to-r from-cyan-500 to-cyan-600 
          hover:from-cyan-600 hover:to-cyan-700 rounded-lg transition-all duration-200 shadow-lg hover:shadow-xl"
        >
          <Plus className="h-5 w-5" />
          Create New Chat
        </button>
      </div>
      <div className="overflow-y-auto h-[calc(100%-5rem)]">
        {chats.map(chat => (
          <motion.div
            key={chat.id}
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            onClick={() => setActiveChat(chat.id)}
            className={`group flex items-center justify-between px-4 py-3 cursor-pointer text-gray-300 
            hover:bg-cyan-800/20 transition-all duration-200 
            ${activeChat === chat.id ? 'bg-cyan-800/30 border-r-4 border-cyan-500' : ''}`}
          >
            <div className="flex items-center gap-2 truncate">
              <MessageSquare className="h-5 w-5 flex-shrink-0" />
              <span className="truncate">{chat.name}</span>
            </div>
            <button
              onClick={(e) => deleteChat(chat.id, e)}
              className="opacity-0 group-hover:opacity-100 p-1.5 hover:text-red-400 transition-all duration-200"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </motion.div>
        ))}
      </div>
    </div>

    {/* Overlay */}
    {isSidebarOpen && (
      <div 
        className="fixed inset-0 bg-black/30 z-20"
        onClick={() => setIsSidebarOpen(false)}
      />
    )}

    {/* Main centered container */}
    <div className="flex-1 flex justify-center">
      {/* Main chat container */}
      <div className="flex flex-col w-full max-w-4xl">
      <header className="relative bg-white/80 backdrop-blur-sm border-b border-gray-200">
  <div className="relative top-0 left-0 right-0 bg-gradient-to-r from-cyan-600 to-cyan-800 text-white text-[0.6rem] md:text-sm py-1">
    <p className="text-center  px-4">
    🚀 We're in Beta! Experience our app early and help us shape the future. Your feedback means the world!
    </p>
  </div>
  
  <div className="flex items-center justify-center px-6 py-4 mt-1">
    {/* Toggle Button */}
    <button
      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      className="absolute left-4 top-16  md:top-16 -translate-y-1/2 p-2.5 text-cyan-800 hover:text-cyan-700 
      bg-cyan-100 hover:bg-cyan-200 rounded-lg transition-all duration-200 hover:scale-105"
    >
      {isSidebarOpen ? (
        <ChevronLeft className="h-5 w-5" />
      ) : (
        <ChevronRight className="h-5 w-5" />
      )}
    </button>

    {/* Logo with Beta Tag */}
    <div className="flex flex-col items-center">
      <div className="flex items-center relative">
        <Hourglass className="h-8 w-8 text-[#17464e]" />
        <span className="text-2xl font-bold bg-gradient-to-r from-[#17464e]  to-[#4daac9] bg-clip-text text-transparent">
          Paisabay
        </span>
        {/* <span className="text-2xl font-bold bg-gradient-to-r from-pink-500 via-purple-500 to-cyan-600 bg-clip-text text-transparent">
          bay
        </span> */}
        <div className="absolute -top-3 -right-12">
        <span className="inline-flex items-center px-2.5 py-0.5 text-xs font-semibold bg-cyan-50 backdrop-blur-md text-cyan-600 rounded-full shadow-sm border border-cyan-200/50">
  BETA
</span>
        </div>
      </div>
    </div>
  </div>
</header>

        <div className="flex-1 overflow-y-auto overflow-x-hidden pl-0 pr-2 py-4">
          <AnimatePresence mode="popLayout">
            {messages[activeChat]?.map((message, index) => (
              <motion.div
                key={`${message.timestamp}-${index}`}
                initial={{ opacity: 0, y: 20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ 
                  duration: 0.3, 
                  delay: index * 0.1,
                  type: "spring",
                  stiffness: 200,
                  damping: 20
                }}
                className={`flex w-full ${message.isUser ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`max-w-[80%] rounded-lg p-4 mb-4 transform transition-all duration-200 hover:shadow-lg ${
                  message.isUser 
                    ? 'bg-gradient-to-br from-cyan-600 to-cyan-700 text-white' 
                    : 'bg-white shadow-sm'
                }`}>  
                  <p className="text-sm text-justify whitespace-pre-wrap">{message.content}</p>
                  <p className="text-xs mt-2 opacity-70">{message.timestamp}</p>
                </div>
              </motion.div>
            ))}
            {isTyping && <TypingIndicator />}
          </AnimatePresence>
          <div ref={messagesEndRef} />
        </div>

        <div className="border-t border-gray-200 bg-white/80 backdrop-blur-sm px-4 py-4">
          <form
            onSubmit={handleSubmit}
            className="relative flex items-center"
          >
            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              rows={1}
              className="w-full p-4 pr-12 rounded-lg border border-gray-300 bg-white focus:outline-none 
              focus:ring-2 focus:ring-cyan-500 transition-all duration-200 shadow-sm hover:shadow-md 
              resize-none overflow-y-clip min-h-[56px] max-h-[200px]"
              disabled={isLoading}
              style={{
                minHeight: '56px',
                maxHeight: '200px'
              }}
            />
            <button
              type="submit"
              disabled={isLoading || !inputMessage.trim()}
              className="absolute right-4 p-2 rounded-lg text-cyan-500 hover:bg-cyan-50
              disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
              style={{
                bottom: '8px'
              }}
            >
              <Send className="h-5 w-5" />
            </button>
          </form>
          
          <AnimatePresence>
          <div className="flex flex-col md:flex-row items-center md:justify-start md:gap-5 gap-0 w-full mt-1 md:mt-5">    {/* Left Button */}
    <QuickActionButton 
      onClick={() => {
        setInputMessage("How are you better than ChatGPT?");
        document.querySelector('textarea').focus();
      }}
    >
      Compare with ChatGPT
    </QuickActionButton>

    

    {/* Right Button */}
    <QuickActionButton onClick={handleWaitlistClick}>
      Join the Waitlist
    </QuickActionButton>
  </div>
          </AnimatePresence>
        </div>

        <footer className="text-center p-3 text-sm text-gray-600 bg-white/80 backdrop-blur-sm border-t border-gray-200">
          <p>© 2024 Paisabay. All rights reserved.</p>
        </footer>
      </div>
    </div>
  </div>
);
};

export default ChatInterface;
